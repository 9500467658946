import { Form, Link } from "remix";

const inputValue = '{"code":200,"message":"Get data successfully","data":[{"id":1,"name":"Full time"},{"id":2,"name":"Part time"},{"id":3,"name":"Internship"}]}';

export function ExampleDoc({
  id,
  title,
  path,
}: {
  id: string;
  title: string;
  path?: string;
}) {
  return (
    <Form
      method="post"
      action="/actions/createFromUrl"
    >
      <div className="flex">
        <input
          hidden
          name="jsonUrl"
          id="jsonUrl"
          value={inputValue}
        />
        <button
          type="submit"
          className="bg-slate-900 px-4 py-2 rounded-md whitespace-nowrap text-lime-300 transition hover:text-lime-500"
          value={title}
        >{title}
        </button>
      </div>
    </Form>
    // <Link
    //   to={`/j/${id}${path ? `?path=${path}` : ""}`}
    //   className="bg-slate-900 px-4 py-2 rounded-md whitespace-nowrap text-lime-300 transition hover:text-lime-500"
    // >
    //   {title}
    // </Link>
  );
}
